import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题
import '@/assets/svgImg' //引入svg Icon

import axios from 'axios';

// 设置全局配置
axios.defaults.withCredentials = true
Vue.prototype.$http = axios


// 在request 拦截器中, 展示进度条 NProgress.start()
// 请求在到达服务器之前，先会调用use中的这个回调函数来添加请求头信息
axios.interceptors.request.use(config => {
  return config
})
// response 拦截器中,  隐藏进度条NProgress.done()
axios.interceptors.response.use(config => {
  return config
}, error => {
})

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  size: 'small'
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');